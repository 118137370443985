import React from "react"
import styled from "styled-components"
import Link from "gatsby-link"
import Prism from "prismjs"
import Layout from "../layouts/"
import Metadata from "../components/shared/Metadata"
import JumpToContent from "../components/shared/JumpToContent"
import { slugify, stripHTML } from "../utilities/functions"
import genericSocialImage from "../images/social-image-generic.png"
import "prismjs/themes/prism.css"

const PostImage = styled("figure")`
  margin: 1em auto 1.5em;
  text-align: center;

  img {
    display: block;
    max-width: 100%;
  }
`

const PostImageCaption = styled("figcaption")`
  font-family: "Merriweather", "Georgia", serif;
  font-style: italic;
  margin: 0.5em auto;
`

const PostHeader = styled("header")`
  border-bottom: 0.25rem solid #f0f0f0;
  padding-bottom: 0.25rem;
  margin-bottom: 2rem;

  h1 {
    font-weight: bold;
  }
`

const PostBody = styled("div")`
  h2,
  h3 {
    font-weight: bold;
  }

  h2,
  h3,
  h4 {
    margin: 2rem auto 1rem;
  }
  p {
    margin: 1rem auto;
  }

  code,
  pre {
    [class*="language-"] {
      background-color: #f5f2f0;
      color: #dd4a68;
    }
  }
  pre[class*="language-"] {
    line-height: 1.25;
    margin: 2.25rem auto;
  }

  hr {
    border: none;
    border-bottom: 1px solid #50596c;
  }

  .post--date {
    color: #ca1211;
    font-weight: 600;
  }
`

const PostNav = styled("nav")`
  margin-bottom: 1em;
`

const MicrodataFooter = styled("footer").attrs({ "aria-hidden": true })`
  [aria-hidden] {
    font-size: 0;
  }
`

export default class BlogPost extends React.Component {
  constructor() {
    super()
  }
  componentDidMount() {
    // highlight all code blocks
    Prism.highlightAll()

    // Apply IDs to h-tags
    const allHeadings = Array.from(
      document.querySelectorAll(["h3", "h4", "h5", "h6"].join(", "))
    )

    allHeadings.forEach(tag => {
      tag.setAttribute("id", slugify(tag.innerText))
    })
  }
  render() {
    const {
      data: { markdownRemark },
    } = this.props
    const { frontmatter, html: __html } = markdownRemark
    const DateLastModified = () =>
      frontmatter.date_modified ? (
        <small>
          Last modified:{" "}
          <span itemProp="dateModified">{frontmatter.date_modified}</span>
        </small>
      ) : null

    return (
      <Layout>
        <section itemProp="mainEntityOfPage">
          <JumpToContent />
          <Metadata
            frontmatter={{
              title: frontmatter.title,
              description: frontmatter.excerpt
                ? frontmatter.excerpt
                : __html.length > 300
                ? `${stripHTML(__html).substr(0, 299)}…`
                : stripHTML(__html),
              og_url: frontmatter.path ? frontmatter.path : null,
              og_image: frontmatter.feat_img
                ? frontmatter.feat_img
                : genericSocialImage,
              twitter_card: "summary_large_image",
              twitter_creator: "@gdavis92",
            }}
          />
          <header className="navbar">
            <PostNav className="navbar-section">
              <Link to="/writing" className="btn btn-link">
                «&nbsp;Back to Writing
              </Link>
            </PostNav>
            <br />
          </header>
          <article
            id={slugify(frontmatter.title)}
            itemScope
            itemType="http://schema.org/Article"
          >
            <PostHeader>
              <PostImage role="group">
                <img
                  src={frontmatter.feat_img}
                  itemProp="image"
                  alt={
                    frontmatter.feat_img_alt
                      ? frontmatter.feat_img_alt
                      : `source: ${frontmatter.feat_img}`
                  }
                  data-printonly
                />
                {frontmatter.feat_img_alt && (
                  <PostImageCaption>
                    {frontmatter.feat_img_alt}
                  </PostImageCaption>
                )}
              </PostImage>
              <h1 itemProp="name" itemProp="headline">
                {frontmatter.title}
              </h1>
              <h2>
                By{" "}
                <span
                  itemProp="author"
                  itemScope
                  itemType="http://schema.org/Person"
                >
                  <span itemProp="name">{frontmatter.author}</span>
                </span>
              </h2>
            </PostHeader>
            <PostBody
              id="content"
              dangerouslySetInnerHTML={{ __html }}
              itemProp="articleBody"
            />
            <MicrodataFooter>
              <meta itemProp="datePublished" content={frontmatter.date} />
              <DateLastModified />
            </MicrodataFooter>
          </article>
        </section>
      </Layout>
    )
  }
}

export const BlogPostByPath = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      frontmatter {
        author
        date(formatString: "MMM DD, YYYY")
        date_modified(formatString: "MMM DD, YYYY")
        path
        title
        feat_img
        feat_img_alt
        excerpt
      }
      html
    }
  }
`
